import 'crypto-js/lib-typedarrays'
import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser
  } from 'amazon-cognito-identity-js';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs'

import * as AWS from 'aws-sdk';

const apiSessionValidationMethod = "validateSession";


@Injectable({
    providedIn: 'root'
})
export class AWSData {

    constructor( public http: HttpClient ) {}

    userPoolId: string = 'us-west-2_2dqeATOeU';
    clientId: string = '24i86dlv33v1vs67h6l6818g6m';
    region: string = "us-west-2";
    sessionUserAttributes: any; 
    cognitoUser: any;

    authenticate(username, pwd, newPasswordCallback, successCallback, errorCallback) {
        var authenticationData = {
            Username : username,
            Password : pwd,
        };
        var authenticationDetails = new AuthenticationDetails(authenticationData);
        var poolData = {
            UserPoolId : this.userPoolId,
            ClientId : this.clientId
        };
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username : username,
            Pool : userPool
        };
        this.cognitoUser = new CognitoUser(userData);

        this.cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                // console.log('access token + ' + result.getAccessToken().getJwtToken());
                console.log(result);
        
                AWS.config.region = this.region;
        
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId : 'us-west-2:f3ee4714-bece-4d02-9fbd-aa90a1206b53', // your identity pool id here
                    Logins : {
                        // Change the key below according to the specific region your user pool is in.
                        'cognito-idp.us-west-2.amazonaws.com/us-west-2_2dqeATOeU' : result.getIdToken().getJwtToken()
                    }
                });
        
                // Instantiate aws sdk service objects now that the credentials have been updated.
                successCallback(result.getIdToken().getJwtToken());
        
            },
        
            onFailure: (err) => {
                console.log(JSON.stringify(err));
                errorCallback(err.message);
            },

            newPasswordRequired: (userAttributes, requiredAttributes) => {

                newPasswordCallback();

                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.
     
                // the api doesn't accept this field back
                delete userAttributes.email_verified;
     
                // store userAttributes on global variable
                this.sessionUserAttributes = userAttributes;
            }
        
        });
    }

    // ... handle new password flow
    handleNewPassword(newPassword, successCallback, errorCallback) {
        this.cognitoUser.completeNewPasswordChallenge(newPassword, this.sessionUserAttributes, {
            onSuccess: (result) => {
                console.log('access token + ' + result.getIdToken().getJwtToken());
                successCallback(result.getIdToken().getJwtToken());
            },
            onFailure: (err) => {
                console.log(JSON.stringify(err));
                errorCallback(err.message);
            }
        });
    }

    forgotPassword(username, successCallback, errorCallback){

        var poolData = {
            UserPoolId : this.userPoolId,
            ClientId : this.clientId
        };
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username : username,
            Pool : userPool
        };

        this.cognitoUser = new CognitoUser(userData);

        this.cognitoUser.forgotPassword({
            onSuccess: (data) => {
                // successfully initiated reset password request
                console.log('Successful password delivery : ' + data);
                successCallback("Password recovery sent to email :) ");
            },
            onFailure: (err) => {
                errorCallback(err.message);
            },

            //Optional automatic callback
            inputVerificationCode: function(data) {
                console.log('Code sent to: ' + data);
                successCallback("Password recovery sent to email :) ");
            },
    
        });
    }

    confirmCodeAndPassword(username, code, newPassword, successCallback, errorCallback) {
        var poolData = {
            UserPoolId : this.userPoolId,
            ClientId : this.clientId
        };
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username : username,
            Pool : userPool
        };

        this.cognitoUser = new CognitoUser(userData);

        this.cognitoUser.confirmPassword(code, newPassword, {
            onSuccess() {
                successCallback('Password reset successfully!');
            },
            onFailure(err) {
                errorCallback(err.message);
            },
        });
    }

    checkSessionValid(apiBaseUrl, idToken){

            try{
                let headers = new HttpHeaders().set('Authorization', idToken);
                headers = headers.set("Content-Type", "application/json; charset=utf-8");

                return this.http
                .post<any>(apiBaseUrl + "?o=" + apiSessionValidationMethod, {}, { headers: headers })
                .pipe(map(this.processSessionValidResponse, this))
                .pipe(catchError(this.handleSessionValidationError));
            }
            catch(ex){
                return of(false);
            }
    }

    processSessionValidResponse(data) {
         return  of(true);
    }

    handleSessionValidationError = (error: HttpErrorResponse) => {
         return of(false);
    }

}

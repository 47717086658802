import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { StorageService } from '../providers/storage-service';
// import { Plugins } from '@capacitor/core';
import { ToastNotificationComponent } from "../toast-notification/toast-notification.component";
import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from 'rxjs';
import { AWSData } from '../providers/aws';

// const { Network } = Plugins;

@Injectable({
    providedIn: 'root'
})
export class Globals {
constructor( 
  public navCtrl: NavController,
  public storageService: StorageService,
  public toast: ToastNotificationComponent,
  public aws: AWSData) {

  }

  apiBaseUrl: string = "https://7uasdigzgf.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-platform-base";
  apiReviewUrl: string = "https://728n5t7oj8.execute-api.us-west-2.amazonaws.com/default/bcreativ-jaguar-mobile-review";
  version: string = "2.04";
  brandId: string = '';
  isPurchaseMode:boolean = false;
  idToken: string = '';
  wifiOnlyDownload: boolean = false;
  isMultiBrand: boolean = false;
  networkHandler: any = {};
  userName: string = "";
  isMobileDevice: boolean = false;
  isAndroid: boolean = false;
  isAndroidFirstLoad:boolean = true;

  // Example value:
  // {
  //   "connected": true,
  //   "connectionType": "wifi"
  // }
  networkStatus: any  = {};

  navigateToSignIn = () => {
      this.navCtrl.navigateForward(['sign-in']);   
  }

  navigateToHome = (refresh = false) => {
    var refreshParamsStr = "false"
    if(refresh)
      refreshParamsStr = refresh.toString();

    var navigationExtras: NavigationExtras = {
      queryParams: {
          r: refreshParamsStr
      }
    };  
    this.navCtrl.navigateForward(['tabs/home'], navigationExtras);   
   }

  async savePurchaseMode(mode) {
    this.isPurchaseMode = mode;
    await this.storageService.set("isPurchaseMode", mode);
}

  async populatePurchaseMode(){
    var isPurchaseMode = await this.storageService.get("isPurchaseMode");
    if(isPurchaseMode != '')
      this.isPurchaseMode = isPurchaseMode;
  }

  async saveIsAndroidFirstLoad(firstLoad) {
    this.isAndroidFirstLoad = firstLoad;
    await this.storageService.set("isAndroidFirstLoad", firstLoad);
}

  async populateIsAndroidFirstLoad(){
    var isAndroidFirstLoad = await this.storageService.get("isAndroidFirstLoad");
    if(isAndroidFirstLoad != '')
      this.isAndroidFirstLoad = isAndroidFirstLoad;
  }

  async saveIsMultiBrand(mode) {
    this.isMultiBrand = mode;
    await this.storageService.set("isMultiBrand", mode);
}

  async populateIsMultiBrand(){
    var isMultiBrand = await this.storageService.get("isMultiBrand");
    if(isMultiBrand != '')
      this.isMultiBrand = isMultiBrand;
  }

  async saveUserName(username) {
    this.userName = username;
    await this.storageService.set("userName", username);
}

  async populateUserName(){
    var userName = await this.storageService.get("userName");
    if(userName != '')
      this.userName = userName;
  }

  async saveAuthToken(idToken) {
    this.idToken = idToken;
    await this.storageService.set("authToken", idToken);
}

  async populateAuthToken(){
    var idToken = await this.storageService.get("authToken");
    if(idToken != '')
      this.idToken = idToken;
  }

  async populateWifiOnlySetting(){
    var setting = await this.storageService.get("wifi-only-download");
    if(setting != '')
      this.wifiOnlyDownload = setting;
  }

  async saveBrandId(brandId) {
    this.brandId = brandId;
    await this.storageService.set("brandId", brandId);
  }

  async populateBrandId(){
    var brandId = await this.storageService.get("brandId");
    if(brandId != '')
      this.brandId = brandId;
  }

   handleNetworkStatus(ignoreConnected = false){
     if(!this.networkStatus.connected){
        this.notConnectedAction();
     }
     else if(this.networkStatus.connected && !ignoreConnected){
      this.toast.presentToast("Internet is back!", 1500);
      this.navigateToHome(false);
     }
   }

   async login(){
    await this.loadGlobals();
   }

   logout(){
    this.saveAuthToken("");
    this.saveBrandId("");
    this.navigateToSignIn();
   }

   resetCurrentBrand(){
    this.saveBrandId("");
    this.navigateToHome(true);  
   }


   async loadGlobals():Promise<void>{
    return new Promise(async (resolve, reject) =>{
      this.populateAuthToken();
      this.populateBrandId();
      this.populateUserName();
      this.populatePurchaseMode();
      this.populateWifiOnlySetting();
      this.populateIsMultiBrand();
      this.populateIsAndroidFirstLoad();

      // Get the current network status
      // this.networkStatus = await Network.getStatus();
      // this.handleNetworkStatus(true);

      // if(this.isEmpty(this.networkHandler)){
      //   this.networkHandler = Network.addListener('networkStatusChange', (status) => {
      //     this.networkStatus = status;
      //     console.log("Network status changed", status);
      //     this.handleNetworkStatus();
      //   });
      // }
      resolve();
    });
   }

   notConnectedAction():void{
    var navigationExtras: NavigationExtras = {
      queryParams: {
          m: "This app requires an internet connection. Please check your connection."
      }
    };
    this.navCtrl.navigateForward(['splash'], navigationExtras);  
   }

   showIssue(m):void{
    var navigationExtras: NavigationExtras = {
      queryParams: {
          m: m
      }
    }
    this.navCtrl.navigateForward(['splash'], navigationExtras);  
   }

   isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  handleHttpError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    this.logout();
    return throwError(errorMessage);
  }

}
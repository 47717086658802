import { Component, ViewEncapsulation, enableProdMode } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Globals } from './providers/globals'

const { SplashScreen } = Plugins;

enableProdMode();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  constructor(
    private platform: Platform,
    public globals: Globals

  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();

      //set mobile device global
      if(this.platform.platforms().filter(p => (p == "android" || p =="ios")).length > 0)
        this.globals.isMobileDevice = true;

      if(this.platform.platforms().filter(p => p == "android").length > 0)
        this.globals.isAndroid = true;

      //load all globals 
      this.globals.login();
    })
    .catch((err) => {
      console.log('ERROR on Load: ', err);
    });
  }
}
